import { IonChip, IonIcon, IonItem, IonLabel, IonSkeletonText, IonThumbnail } from "@ionic/react";
import { banOutline, unlinkOutline, wifi } from "ionicons/icons";
import { useDeviceQuery } from "../query/deviceQuery";
import styles from './DeviceListItem.module.css';

interface DeviceListItemProps {
    deviceId: string;
}

const DeviceListItem: React.FC<DeviceListItemProps> = ({ deviceId }) => {
    const deviceQuery = useDeviceQuery(deviceId);

    if (deviceQuery.isLoading) {
        return <IonItem routerLink={`/devices/${deviceId}`} className={styles.item}>
            <IonThumbnail slot="start">
                <IonSkeletonText animated={true}></IonSkeletonText>
            </IonThumbnail>
            <IonLabel>
                <h2>{deviceId}</h2>
                <p><IonSkeletonText animated={true} style={{ width: '160px' }} /></p>
                <IonChip color="medium" style={{ marginLeft: 0 }}>
                    <IonLabel><IonSkeletonText animated={true} style={{ width: '75px' }} /></IonLabel>
                </IonChip>
            </IonLabel>
        </IonItem>
    }
    else if (deviceQuery.isError) {
        return <IonItem routerLink={`/devices/${deviceId}`} className={styles.item}>
            <IonThumbnail slot="start">
                <img src="/assets/device/onlycat-device.png" alt="OnlyCat Device" />
            </IonThumbnail>
            <IonLabel>
                <h2>{deviceId}</h2>
                <IonChip color="danger" style={{ marginLeft: 0 }}>
                    <IonIcon icon={banOutline} />
                    <IonLabel>Unavailable</IonLabel>
                </IonChip>
            </IonLabel>
        </IonItem>
    }
    else {
        const device = deviceQuery.data;

        return <IonItem routerLink={`/devices/${device.deviceId}`} className={styles.item}>
            <IonThumbnail slot="start">
                <img src="/assets/device/onlycat-device.png" alt="OnlyCat Device" />
            </IonThumbnail>
            <IonLabel>
                <h2>{device.deviceId}</h2>
                <p>{device.description}</p>
                <IonChip color={device.connectivity?.connected ? 'success' : 'danger'} style={{ marginLeft: 0 }}>
                    <IonIcon icon={device.connectivity?.connected ? wifi : unlinkOutline} />
                    <IonLabel>{device.connectivity?.connected ? 'Online' : 'Offline'}</IonLabel>
                </IonChip>
            </IonLabel>
        </IonItem>
    }
};

export default DeviceListItem;
