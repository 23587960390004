import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLabel, IonList, IonPage, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from '@ionic/react';
import { IonButtons } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useDeviceTransitPolicyMutation, useDeviceTransitPolicyQuery } from '../query/deviceTransitPolicyQuery';
import { JsonEditor } from 'json-edit-react'
import { TransitPolicy } from '../models/TransitPolicy';
import { list, codeSlash, chevronForward } from 'ionicons/icons';
import { EventClassification, EventTriggerSource } from '../models/DeviceEvent';
import RfidChip from '../components/RfidChip';
import styles from './DoorPolicyModal.module.css';

enum Tabs {
    Rules,
    Code
}

const DoorPolicyModal = ({ dismiss, deviceTransitPolicyId }: { dismiss: (data?: string | null | undefined | number, role?: string) => void, deviceTransitPolicyId: number }) => {
    const [edited, setEdited] = useState(false);
    const query = useDeviceTransitPolicyQuery(deviceTransitPolicyId);
    const [transitPolicy, setTransitPolicy] = useState<TransitPolicy>();
    const [tab, setTab] = useState<Tabs>(Tabs.Rules);
    const mutation = useDeviceTransitPolicyMutation(deviceTransitPolicyId);

    useEffect(() => {
        setTransitPolicy(query.data?.transitPolicy);
        setEdited(false);
    }, [query.data?.transitPolicy]);

    const save = async () => {
        await mutation.mutateAsync({ transitPolicy });

        dismiss(undefined, 'confirm');
    }

    return (
        <IonPage>
            <IonHeader translucent>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton color="medium" onClick={() => dismiss(null, 'cancel')}>
                            Cancel
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{query.data?.name || 'Door Policy'}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={save} strong={true} disabled={!edited || mutation.isPending}>
                            Save
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonToolbar>
                    <IonSegment value={tab} onIonChange={e => setTab(e.detail.value as Tabs)}>
                        <IonSegmentButton layout="icon-start" value={Tabs.Rules}>
                            <IonIcon icon={list} />
                            <IonLabel>Rules</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton layout="icon-start" value={Tabs.Code}>
                            <IonIcon icon={codeSlash} />
                            <IonLabel>Code</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {tab === Tabs.Rules && <>
                    <IonList>
                        {transitPolicy?.rules?.map((rule, idx) => (
                            <IonCard color="light" key={idx}>
                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow class="ion-align-items-center">
                                            <IonCol size="6">
                                                {!rule?.criteria && <h4>Always</h4>}
                                                {rule?.criteria && Object.entries(rule?.criteria).map(([key, value], criteriaIdx) => {
                                                    let els: [JSX.Element | string] = [criteriaIdx === 0 ? 'If ' : ', and '];
                                                    value = Array.isArray(value) ? value : [value];

                                                    switch (key) {
                                                        case 'timeRange':
                                                            for (let i = 0; i < value.length; i++) {
                                                                els.push(<span>{!i ? ' the time is between ' : ' or '}</span>);
                                                                els.push(<strong>{value[i]}</strong>);
                                                            }
                                                            break;

                                                        case 'eventTriggerSource':
                                                            for (let i = 0; i < value.length; i++) {
                                                                els.push(<span>{!i ? ' the event was triggered by ' : ' or '}</span>);
                                                                els.push(<strong>{EventTriggerSource[value[i]]}</strong>);
                                                            }
                                                            break;

                                                        case 'eventClassification':
                                                            for (let i = 0; i < value.length; i++) {
                                                                els.push(<span>{!i ? ' appears to be ' : ' or '}</span>);
                                                                els.push(<strong>{EventClassification[value[i]]}</strong>);
                                                            }
                                                            break;

                                                        case 'rfidCode':
                                                            for (let i = 0; i < value.length; i++) {
                                                                els.push(<span>{!i ? ' read microchip ' : ' or '}</span>);
                                                                els.push(<RfidChip rfidCode={value[i]}></RfidChip>);
                                                            }
                                                            break;

                                                        default:
                                                            els.push(<span>Unknown Criteria</span>)
                                                    }

                                                    return <>{els}</>;
                                                })}
                                            </IonCol>
                                            <IonCol size="1">
                                                <IonIcon icon={chevronForward} size="large" />
                                            </IonCol>
                                            <IonCol size="5">
                                                {!rule?.action && <h4>Do Nothing</h4>}
                                                {rule?.action && Object.entries(rule?.action).map(([key, value], actionIdx) => {
                                                    let els: [JSX.Element | string | undefined] = [actionIdx === 0 ? undefined : ', and '];

                                                    switch (key) {
                                                        case 'lock':
                                                            els.push(<strong>{value ? 'Lock' : 'Unlock'}</strong>);
                                                            els.push(<span> the door</span>);
                                                            break;

                                                        case 'sound':
                                                            els.push(<span> play the </span>);
                                                            els.push(<strong>{value}</strong>);
                                                            els.push(<span> sound</span>);
                                                            break;

                                                        default:
                                                            els.push(<span>Unknown Action</span>)
                                                    }

                                                    return <>{els}</>;
                                                })}
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>
                                </IonCardContent>
                            </IonCard>
                        ))}
                        <div className={styles.helperText}>We're still working on completing Door Policy editing in the app.
                            For now, to edit Door Policies, please contact the OnlyCat team.</div>
                    </IonList>
                </>}
                {tab === Tabs.Code && <>
                    <IonCard color="primary">
                        <IonCardHeader>
                            <IonCardTitle>Door Policy Code Editor</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>This feature is provided for advanced users and home automation enthusiasts.</IonCardContent>
                    </IonCard>
                    {transitPolicy && <JsonEditor data={transitPolicy}
                        showArrayIndices={false}
                        showCollectionCount="when-closed"
                        maxWidth="100%"
                        rootName="transitPolicy"
                        keySort={(a, b) => {
                            if (a === 'description') return -1;
                            if (b === 'description') return 1;
                            if (a === 'criteria') return -1;
                            if (b === 'criteria') return 1;
                            return a.localeCompare(b);
                        }}
                        onUpdate={({ newData }) => {
                            setTransitPolicy(newData as TransitPolicy);
                            setEdited(true);
                        }}
                    />}
                </>}
            </IonContent>
        </IonPage>
    );
};

export default DoorPolicyModal;